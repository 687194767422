// FormatBarcode.js
import React, { useCallback, useState, useEffect } from 'react';
import styles from './TwoStepPayment.module.css';

const FormatBarcode = ({ code, billType }) => {
  const [data, setData] = useState(null); 
  const [loading, setLoading] = useState(false); 
  const [error, setError] = useState(null); 

  const formatBarcode = (code) => {
    const onlyNumbers = code.replace(/\D/g, ''); // Remove tudo que não for número

    if (onlyNumbers.length === 47) {
      return [
        onlyNumbers.slice(0, 10),        
        onlyNumbers.slice(10, 21),      
        onlyNumbers.slice(21, 32),      
        onlyNumbers.slice(32, 33),      
        onlyNumbers.slice(33)           
      ].join(' ');
    }

    if (onlyNumbers.length === 48) {
      return [
        `${onlyNumbers.slice(0, 11)}-${onlyNumbers.slice(11, 12)}`,   
        `${onlyNumbers.slice(12, 23)}-${onlyNumbers.slice(23, 24)}`, 
        `${onlyNumbers.slice(24, 35)}-${onlyNumbers.slice(35, 36)}`, 
        `${onlyNumbers.slice(36, 47)}-${onlyNumbers.slice(47, 48)}`  
      ].join(' ');
    }

    return code; // Retorna o código original se não for 47 ou 48 dígitos
  };

  // Função para buscar os dados na API
  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null); // Limpar erro anterior
    try {
      const response = await fetch('https://carinapaga.com.br/api/app/consultation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ billType }),
      });
      const result = await response.json();
      if (response.ok) {
        setData(result);
        console.log('Dados retornados da API:', result); // Exibe os dados retornados no console
      } else {
        setError(result.message || 'Erro desconhecido');
        console.log('Erro na resposta da API:', result.message || 'Erro desconhecido'); // Log de erro
      }
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      setError('Erro ao conectar com o servidor.');
    } finally {
      setLoading(false);
    }
  }, [billType]);

  useEffect(() => {
    if (billType) {
      fetchData();
    }
  }, [billType, fetchData]); // Reexecuta a função quando o billType mudar

  return (
    <div className={styles.barcodeContainer}>
      <h3>Código de Barras Formatado</h3>
      <span className={styles.formattedBarcode}>{formatBarcode(code)}</span>
      {loading && <p>Carregando...</p>}
      {error && <p style={{ color: 'red' }}>Erro: {error}</p>}
      {data && (
        <div>
          <h4>Dados da Fatura:</h4>
          <pre>{JSON.stringify(data, null, 2)}</pre> {/* Exibe os dados no formato JSON */}
        </div>
      )}
    </div>
  );
};

export default FormatBarcode;
