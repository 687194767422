import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './PagarBoletos.module.css';
import Sidebar from './Sidebar';
import FormatBarcode from './FormatBarcode'; // Importando o componente FormatBarcode

const PagamentoBoletos = () => {
  const [valor, setValor] = useState('');
  const [formattedBarcode, setFormattedBarcode] = useState(''); // Estado para o código formatado
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const isPixCode = (code) => code.replace(/\D/g, '').length === 32;

  const formatBarcode = (code) => {
    const onlyNumbers = code.replace(/\D/g, ''); // Remove tudo que não for número

    if (onlyNumbers.length === 47) {
      return [
        onlyNumbers.slice(0, 10),
        onlyNumbers.slice(10, 21),
        onlyNumbers.slice(21, 32),
        onlyNumbers.slice(32, 33),
        onlyNumbers.slice(33)
      ].join(' ');
    }

    if (onlyNumbers.length === 48) {
      return [
        `${onlyNumbers.slice(0, 11)}-${onlyNumbers.slice(11, 12)}`,
        `${onlyNumbers.slice(12, 23)}-${onlyNumbers.slice(23, 24)}`,
        `${onlyNumbers.slice(24, 35)}-${onlyNumbers.slice(35, 36)}`,
        `${onlyNumbers.slice(36, 47)}-${onlyNumbers.slice(47, 48)}`
      ].join(' ');
    }

    return code; // Retorna o código original se não for 47 ou 48 dígitos
  };

  const handleSimular = () => {
    const unformattedValor = valor.replace(/\D/g, ''); // Remove a formatação antes de enviar

    if (isPixCode(unformattedValor)) {
      setErrorMessage('Código PIX detectado! Por favor, insira um código de barras de boleto.');
      return;
    }

    setIsLoading(true); // Ativa o estado de carregamento

    // Simula o tempo de processamento (5 segundos)
    setTimeout(() => {
      setIsLoading(false); // Desativa o estado de carregamento
      setFormattedBarcode(formatBarcode(unformattedValor)); // Define o código formatado
    }, 5000);
  };

  const handleChange = (e) => {
    setValor(e.target.value);
    setFormattedBarcode(''); // Reseta o código formatado quando o valor muda
  };

  useEffect(() => {
    const storedCpfCnpj = localStorage.getItem('cpfcnpj');

    if (!storedCpfCnpj) {
      history.push('/login');
    } else {
      history.push('/pagar-boletos');
    }
  }, [history]);

  return (
    <div className="form-container">
      <div className={styles.form}>
        <div className={styles.dashboardContent}>
          <Sidebar />
          <main className={styles.mainContent}>
            <div className={styles.cardContainer}>
              <div className={styles.card}>
                {isLoading ? ( // Verifica se está carregando
                  <div className={styles.loadingContainer}>
                    <div className={styles.spinner}></div> {/* Loading Spinner */}
                    <p>
                      Estamos processando o seu código de barras. Por favor, aguarde enquanto finalizamos a verificação no nosso sistema.
                    </p>

                  </div>
                ) : formattedBarcode ? ( // Exibe o código formatado, substituindo o card de simulação
                  <div className={styles.resultContainer}>
                    <h2>Resultado da Consulta:</h2>
                    <FormatBarcode code={formattedBarcode} /> {/* Passando o código formatado para o componente FormatBarcode */}
                  </div>
                ) : (
                  <>
                    <h2>Simule os seus pagamentos com a CarinaPaga!<br /></h2>
                    <p>
                      Com a CarinaPaga, você pode simular diferentes opções de pagamento para suas faturas e despesas. Nossa plataforma oferece ferramentas intuitivas para que você possa visualizar e comparar alternativas de parcelamento e quitação, proporcionando uma visão clara e prática do impacto financeiro de suas escolhas. Experimente nossa simulação para encontrar a solução que melhor se adapta ao seu orçamento e aproveite uma gestão de pagamentos mais eficiente e personalizada.
                    </p>
                    <br />
                    <div className={styles.simulationCard}>
                      <h2>Insira aqui o código de barras do seu boleto/faturas</h2>
                      <input
                        type="text"
                        id="barcode"
                        placeholder="Código de Barras"
                        className={styles.input}
                        value={valor}
                        onChange={handleChange}
                      />
                      <button onClick={handleSimular}>Simular</button>
                      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                      <p>
                        Seu boleto/fatura será pesquisado em nosso sistema em alguns instantes após o clique no botão.
                      </p>
                      <p style={{ marginTop: '-20px' }}>
                        Consulte as taxas de serviço da CarinaPaga:
                        <br />
                        <a href="https://www.carinapaga.com" className={styles.registerLink}>Taxas & Encargos</a>
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default PagamentoBoletos;
